import React, { Component } from "react"
import RenderList from "../RenderList/RenderList"
import SmallRenderList from "../RenderList/SmallRenderList"
import { FaTh, FaAlignJustify } from "react-icons/fa"
import Title from "../Headings/Title"

const getCategories = items => {
  let categoryItems = items.map(item => {
    return item.node.frontmatter.category
  })
  let uniqueCategories = new Set(categoryItems)
  let categories = Array.from(uniqueCategories)
  categories = ["All Posts", ...categories]
  return categories
}

class BlogItems extends Component {
  state = {
    togglelayout: true,
    items: this.props.items.allMdx.edges,
    blogPostItems: this.props.items.allMdx.edges,
    categories: getCategories(this.props.items.allMdx.edges),
    selectedItem:
      getCategories(this.props.items.allMdx.edges) &&
      getCategories(this.props.items.allMdx.edges)[0],
  }

  handleItems = category => {
    if (category === "All Posts") {
      this.setState({
        blogPostItems: [...this.state.items],
        selectedItem: category,
      })
    } else {
      this.setState({
        blogPostItems: [
          ...this.state.items.filter(edge => {
            return edge.node.frontmatter.category === category
          }),
        ],
        selectedItem: category,
      })
    }
  }

  render() {
    return (
      <section className="blog-list-section">
        <Title>Blog Articles... </Title>
        <div className="container py-5">
          <div className="text-center mb-2">
            {this.state.categories.map((category, i) => {
              return (
                <button
                  type="button"
                  key={i}
                  onClick={() => this.handleItems(category)}
                  className={
                    this.state.selectedItem === category
                      ? "btn m-1 bg-secondary text-light shadow"
                      : "btn btn-outline-primary m-1"
                  }
                >
                  {category}
                </button>
              )
            })}
          </div>

          <div className="text-center mb-4">
            <button
              className="btn bg-secondary text-light shadow"
              onClick={() =>
                this.setState({ togglelayout: !this.state.togglelayout })
              }
            >
              {this.state.togglelayout ? <FaAlignJustify /> : <FaTh />}
            </button>
          </div>

          {this.state.togglelayout ? (
            <div className="row justify-content-center">
              {this.state.blogPostItems.map(RenderList)}
            </div>
          ) : (
            <div className="row justify-content-center">
              {this.state.blogPostItems.map(SmallRenderList)}
            </div>
          )}
        </div>
      </section>
    )
  }
}

export default BlogItems
