import React from "react"
import { Link } from "gatsby"
import Image from "../Images/Images"

const SmallRenderList = ({ node }) => {
  return (
    <div key={node.fields.slug} className="col-lg-12 col-md-12 col-sm-12">
      <Link
        to={`/blog${node.fields.slug}`}
        className="card shadow rounded list-group-item list-group-item-action border-primary mb-2"
        style={{
          backgroundColor: "#" + Math.random().toString(16).slice(-6) + "10",
        }}
      >
        <div className="d-flex w-100 justify-content-between align-items-center m-1">
          <div>
            <h5 className="text-primary fw-bold mb-1">
              {node.frontmatter.title}
            </h5>

            <small className="text-muted mb-1">{node.frontmatter.date}</small>
          </div>
          <div>
            {node.frontmatter.image ? (
              <Image
                className="shadow m-1"
                filename={`${node.frontmatter.image}.jpg`}
                alt={node.frontmatter.image}
                style={{
                  width: "100px",
                  height: "65px",
                  borderRadius: "4px",
                }}
              />
            ) : (
              <div
                style={{
                  width: "100px",
                  height: "65px",
                  borderRadius: "4px",
                }}
              ></div>
            )}
          </div>
        </div>
      </Link>
    </div>
  )
}

export default SmallRenderList
