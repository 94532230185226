import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import Featured from "../components/Featured/Featured"
import BlogFilter from "../components/BlogFilter/BlogFilter"

const BlogPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Blog" />
      <Featured />
      <BlogFilter items={data} />
    </Layout>
  )
}

export default BlogPage

export const query = graphql`
  query blogPostsList {
    allMdx(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {
        frontmatter: { featured: { eq: false } }
        fileAbsolutePath: { regex: "/(blog)/.*.(mdx)/" }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          timeToRead
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            author
            category
            description
            image
          }
        }
      }
    }
  }
`
