import React, { useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Image from "../Images/Images"
import { FaTh, FaAlignJustify } from "react-icons/fa"
import kebabCase from "lodash.kebabcase"
import Title from "../Headings/Title"

const Featured = () => {
  const data = useStaticQuery(
    graphql`
      query blogFeatured {
        allMdx(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {
            frontmatter: { featured: { eq: true } }
            fileAbsolutePath: { regex: "/(blog)/.*.(mdx)/" }
          }
        ) {
          edges {
            node {
              fields {
                slug
              }
              body
              timeToRead
              frontmatter {
                title
                date(formatString: "DD MMMM, YYYY")
                author
                category
                description
                image
              }
            }
          }
        }
      }
    `
  )

  const allPosts = data.allMdx.edges
  const [ToggleLayout, setToggleLayout] = useState(true)
  const toggle = () => setToggleLayout(!ToggleLayout)

  return (
    <section className="featured-section">
      <Title>Featured Articles...</Title>
      <div className="container-fluid py-5">
        <div className="text-center mb-4">
          <button
            onClick={toggle}
            id="ToggleLayout"
            className="btn bg-secondary text-light shadow"
          >
            {ToggleLayout ? <FaAlignJustify /> : <FaTh />}
          </button>
        </div>

        {ToggleLayout ? (
          <div className="row justify-content-center">
            {allPosts.map(edge => {
              return (
                <div
                  key={edge.node.fields.slug}
                  className="col-lg-6 col-md-12 col-sm-12"
                >
                  <div
                    className="card shadow mb-4 border-primary"
                    style={{
                      backgroundColor:
                        "#" + Math.random().toString(16).slice(-6) + "10",
                    }}
                  >
                    <div
                      className="featured-card bg-transparent card-body border-0 no-rounded"
                      id="hover"
                    >
                      {edge.node.frontmatter.image ? (
                        <Image
                          className="card-img gradient shadow"
                          filename={`${edge.node.frontmatter.image}.jpg`}
                          alt={edge.node.frontmatter.image}
                          style={{ borderRadius: "4px", height: "360px" }}
                        />
                      ) : (
                        <div
                          style={{ borderRadius: "4px", height: "360px" }}
                        ></div>
                      )}

                      <div className="card-img-overlay">
                        <div className="card-body">
                          <p>
                            <Link
                              to={`/category/${kebabCase(
                                edge.node.frontmatter.category
                              )}`}
                              className="badge bg-primary text-light text-decoration-none shadow me-1"
                              style={{ fontSize: "0.8em" }}
                            >
                              {edge.node.frontmatter.category}
                            </Link>
                          </p>
                          <Link
                            to={`/blog${edge.node.fields.slug}`}
                            className="text-decoration-none"
                          >
                            <h4 className="card-title fw-bold">
                              {edge.node.frontmatter.title}
                            </h4>
                            <p className="card-text hover-visible">
                              {edge.node.frontmatter.description}
                            </p>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div
                      className="card-footer d-flex justify-content-between align-items-center px-2 py-1"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "50",
                      }}
                    >
                      <span className="small text-muted d-flex justify-content-between align-items-center">
                        <Link
                          to={`/author/${kebabCase(
                            edge.node.frontmatter.author
                          )}`}
                        >
                          <Image
                            className="card-img-top shadow mx-1"
                            filename={`${edge.node.frontmatter.author}.jpg`}
                            alt={edge.node.frontmatter.author}
                            style={{
                              borderRadius: "50%",
                              height: "40px",
                              width: "40px",
                              border: "1px solid #7a5bad",
                            }}
                          />
                        </Link>
                        {edge.node.frontmatter.author}
                      </span>
                      <span className="small text-muted">
                        {edge.node.frontmatter.date}
                      </span>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <div className="row justify-content-center">
            {allPosts.map(edge => {
              return (
                <div
                  key={edge.node.fields.slug}
                  className="col-lg-12 col-md-12 col-sm-12"
                >
                  <Link
                    to={`/blog${edge.node.fields.slug}`}
                    className="card shadow rounded list-group-item list-group-item-action border-primary mb-2"
                    style={{
                      backgroundColor:
                        "#" + Math.random().toString(16).slice(-6) + "10",
                    }}
                  >
                    <div className="d-flex w-100 justify-content-between align-items-center m-1">
                      <div>
                        <h5 className="text-primary fw-bold mb-1">
                          {edge.node.frontmatter.title}
                        </h5>

                        <small className="text-muted mb-1">
                          {edge.node.frontmatter.date}
                        </small>
                      </div>
                      <div>
                        {edge.node.frontmatter.image ? (
                          <Image
                            className="shadow m-1"
                            filename={`${edge.node.frontmatter.image}.jpg`}
                            alt={edge.node.frontmatter.image}
                            style={{
                              width: "100px",
                              height: "65px",
                              borderRadius: "4px",
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              width: "100px",
                              height: "65px",
                              borderRadius: "4px",
                            }}
                          ></div>
                        )}
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </section>
  )
}

export default Featured
