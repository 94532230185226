import React from "react"
import { Link } from "gatsby"
import Image from "../Images/Images"
import kebabCase from "lodash.kebabcase"

const RenderList = ({ node }) => {
  return (
    <div key={node.fields.slug} className="col-lg-4 col-md-6 col-sm-12">
      <div
        className="card shadow mb-4 border-primary"
        style={{
          backgroundColor: "#" + Math.random().toString(16).slice(-6) + "10",
        }}
      >
        <div className="card-body">
          <p>
            <Link
              to={`/category/${kebabCase(node.frontmatter.category)}`}
              className="badge bg-primary text-light text-decoration-none shadow me-1"
              style={{ fontSize: "0.8em" }}
            >
              {node.frontmatter.category}
            </Link>
          </p>
          <Link
            to={`/blog${node.fields.slug}`}
            className="text-decoration-none"
          >
            {node.frontmatter.image ? (
              <Image
                className="card-img-top shadow mb-4"
                filename={`${node.frontmatter.image}.jpg`}
                alt={node.frontmatter.image}
                style={{ borderRadius: "4px", height: "180px" }}
              />
            ) : (
              <div style={{ borderRadius: "4px", height: "180px" }}></div>
            )}
            <h4 className="card-title fw-bold">{node.frontmatter.title}</h4>
            <p className="card-text">{node.frontmatter.description}</p>{" "}
          </Link>
        </div>
        <div
          className="card-footer d-flex justify-content-between align-items-center px-2 py-1"
          style={{
            backgroundColor: "#" + Math.random().toString(16).slice(-6) + "50",
          }}
        >
          <span className="small text-muted d-flex justify-content-between align-items-center">
            <Link to={`/author/${kebabCase(node.frontmatter.author)}`}>
              <Image
                className="card-img-top shadow mx-1"
                filename={`${node.frontmatter.author}.jpg`}
                alt={node.frontmatter.author}
                style={{
                  borderRadius: "50%",
                  height: "40px",
                  width: "40px",
                  border: "1px solid #7a5bad",
                }}
              />
            </Link>
            {node.frontmatter.author}
          </span>
          <span className="small text-muted">{node.frontmatter.date}</span>
        </div>
      </div>
    </div>
  )
}

export default RenderList
